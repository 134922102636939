<template>
  <div class="row">
    <div class="col-4">
      <div class="card">
        <div class="card-body">
          <div class="d-flex flex-column align-items-center text-center">
            <div class="mt-3">
              <h4>{{ vehicle.registration }}</h4>
            </div>
          </div>
          <hr class="my-4">
          <ul class="list-group list-group-flush mb-2">
            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
              <h6 class="mb-0">Make</h6>
              <span v-if="!edit" class="text-white">{{ vehicle.make || "N/A" }}</span>
              <input v-else class="form-control" style="width: 75%;" type="text" v-model="vehicle.make">
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
              <h6 class="mb-0">Model</h6>
              <span v-if="!edit" class="text-white">{{ vehicle.model || "N/A" }}</span>
              <input v-else class="form-control" style="width: 75%;" type="text" v-model="vehicle.model">
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
              <h6 class="mb-0">Colour</h6>
              <span v-if="!edit" class="text-white">{{ vehicle.colour || "N/A" }}</span>
              <input v-else class="form-control" style="width: 75%;" type="text" v-model="vehicle.colour">
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
              <h6 class="mb-0">MOT Date</h6>
              <span v-if="!edit" class="text-white">{{ vehicle.mot_date_formatted || "N/A" }}</span>
              <input v-else class="form-control" style="width: 75%;" type="date" v-model="vehicle.mot_date">
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
              <h6 class="mb-0">Tax Date</h6>
              <span v-if="!edit" class="text-white">{{ vehicle.tax_date_formatted || "N/A" }}</span>
              <input v-else class="form-control" style="width: 75%;" type="date" v-model="vehicle.tax_date">
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
              <h6 class="mb-0">Service Date</h6>
              <span v-if="!edit" class="text-white">{{ vehicle.service_date_formatted || "N/A" }}</span>
              <input v-else class="form-control" style="width: 75%;" type="date" v-model="vehicle.service_date">
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
              <h6 class="mb-0">Tracker IMEI</h6>
              <span v-if="!edit" class="text-white">{{ vehicle.tracker_imei || "N/A" }}</span>
              <input v-else class="form-control" style="width: 75%;" type="text" v-model="vehicle.tracker_imei">
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
              <h6 class="mb-0">Type</h6>
              <span v-if="!edit" class="text-white">{{ vehicle.type || "N/A" }}</span>
              <select v-else v-model="vehicle.type" :value="vehicle.type" class="form-control bg-dark">
                <option value="Engineer">Engineer</option>
                <option value="Office">Office</option>
                <option value="Personal">Personal</option>
              </select>
            </li>
          </ul>

          <submit-button :background="'btn btn-sm btn-primary ms-2'" style="float: right;" :clicked='updating'
            @click="saveChanges" :text="'Save Changes'"></submit-button>
          <button class="btn btn-sm ms-2" :class="{ 'btn-light': !edit, 'btn-danger': edit }" @click="edit = !edit"
            style="float: right;">{{ edit ? 'Stop Editing' : 'Edit' }}</button>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="card">
        <div class="card-body">

          <h5 class="card-title">User Assignment</h5>

          <pagination @search="loadUsers" :data="userPagination"></pagination>

          <table id="vehicleUserAssignment" class="table table-hover" style="width:100%">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in users" :key="user.id + '-role'" style="cursor: pointer;">
                <td>{{ user.id }}</td>
                <td>{{ user.fname }} {{ user.lname }}</td>
                <td>
                  <button class="btn btn-sm btn-danger" @click="removeUser(user)" v-if="user.assigned">Remove
                    User</button>
                  <button class="btn btn-sm btn-success" @click="assignUser(user)" v-else>Add User</button>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th></th>
              </tr>
            </tfoot>
          </table>

          <pagination @search="loadUsers" :data="userPagination"></pagination>

        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="card">
        <div class="card-body">

          <h5 class="card-title">Vehicle Inspection Record</h5>

          <table id="vehicleUserAssignment" class="table table-hover" style="width:100%">
            <thead>
              <tr>
                <th>Inspected By</th>
                <th>Datetime of Inspection</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="record in inspectionRecord" :key="record.id" style="cursor: pointer;" @click="$router.push({name: 'admin.vehicles.inspection', params: {id: record.id}})">
                <td>{{ record.fname }} {{ record.lname }}</td>
                <td><Time :datetime="record.created_at" format="dd/MM/yyyy HH:mm"></Time></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import SubmitButton from '../../../components/SubmitButton.vue';
import { DateTime } from 'luxon';
import Pagination from "../../../components/Pagination";
import Time from '../../../components/Time';

export default {
  name: "Vehicle",
  components: {
    SubmitButton, Pagination, Time
  },
  props: ['id'],
  data() {
    return {
      vehicle: {
        id: null
      },
      updating: false,
      edit: false,
      users: [],
      userPagination: {},
      inspectionRecord: []
    }
  },
  mounted() {
    this.loadVehicle();
    this.loadUsers();
    this.loadVehicleInspection();
  },
  methods: {
    loadVehicle() {
      axios.get(`${process.env.VUE_APP_API_URL}/v1/vehicles/${this.id}`)
        .then(response => {
          this.vehicle = response.data.vehicle;
          try {
            this.vehicle.mot_date_formatted = DateTime.fromISO(this.vehicle.mot_date).toFormat('dd/MM/yyyy');
            this.vehicle.mot_date = DateTime.fromISO(this.vehicle.mot_date).toFormat('yyyy-MM-dd');
          } catch (err) {
            this.vehicle.mot_date_formatted = null;
          }
          try {
            this.vehicle.tax_date_formatted = DateTime.fromISO(this.vehicle.tax_date).toFormat('dd/MM/yyyy');
            this.vehicle.tax_date = DateTime.fromISO(this.vehicle.tax_date).toFormat('yyyy-MM-dd');
          } catch (err) {
            this.vehicle.tax_date_formatted = null;
          }
          try {
            this.vehicle.service_date_formatted = DateTime.fromISO(this.vehicle.service_date).toFormat('dd/MM/yyyy');
            this.vehicle.service_date = DateTime.fromISO(this.vehicle.service_date).toFormat('yyyy-MM-dd');
          } catch (err) {
            this.vehicle.service_date_formatted = null;
          }
        })
        .catch(error => {
          this.$error("Failed to load vehicle", error);
        })
    },
    loadVehicleInspection() {
      axios.get(`${process.env.VUE_APP_API_URL}/v1/checklist/vehicle/${this.id}/response`)
        .then(response => {
          this.inspectionRecord = response.data.submissions;
        })
        .catch(error => {
          this.$error("Failed to load vehicle inspection record", error);
        })
    },
    saveChanges() {
      axios.patch(`${process.env.VUE_APP_API_URL}/v1/vehicles/${this.id}`, this.vehicle)
        .then(() => {
          this.$success("Updated vehicle");
          this.loadVehicle();
        })
        .catch(error => {
          this.$error("Failed to update vehicle", error);
        })
    },
    loadUsers(page) {
      axios.get(`${process.env.VUE_APP_API_URL}/v1/users?page=${page}`)
        .then(response => {
          this.users = response.data.users;
          this.users.forEach((user) => {
            let assignedUser = this.vehicle.users.filter((u) => {
              return u.id == user.id;
            });
            user.assigned = assignedUser.length > 0;
          });
          this.userPagination = response.data.pagination;
        })
        .catch(error => {
          this.$error("Failed to load users", error);
        })
    },
    assignUser(user) {
      axios.put(`${process.env.VUE_APP_API_URL}/v1/vehicles/${this.id}/users`, {
        user_id: user.id
      })
        .then(() => {
          this.$success("Added user to vehicle");
          this.vehicle.users.push(user);
          user.assigned = true;
        })
        .catch(error => {
          this.$error("Failed to add user to vehicle", error);
        })
    },
    removeUser(user) {
      axios.delete(`${process.env.VUE_APP_API_URL}/v1/vehicles/${this.id}/users`, {
        data: {
          user_id: user.id
        }
      })
        .then(() => {
          this.$success("Removed user from vehicle");
          this.vehicle.users = this.vehicle.users.filter((u) => {
            return u.id !== user.id;
          });
          user.assigned = false;
        })
        .catch(error => {
          this.$error("Failed to remove user from vehicle", error);
        })
    }
  }
}
</script>

<style scoped></style>